@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfChc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrJJnedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLGT9Z1JlEA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
  color: #222;
}
.btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: #7f7f7f;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 50px;
  font-weight: 500;
}
.btn-more:hover .arrow-right-icon {
  transform: translate3d(5px, 0, 0);
}
.btn-more .arrow-right-icon {
  height: 14px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}
.btn-more-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background: #f1f1f1;
}
.btn-more-border:hover .arrow-right-icon {
  transform: translate3d(5px, 0, 0);
}
.btn-more-border a {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  color: #7f7f7f;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.btn-more-border a .arrow-right-icon {
  height: 13px;
  transition: transform 0.3s ease;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #2181bd;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.gallery-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gallery-flexbox a {
  margin-top: calc(4% / 3);
  width: 24%;
  padding-top: 24%;
  position: relative;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0);
  transform-origin: center bottom;
  transition: all 0.4s;
}
.gallery-flexbox a:hover {
  transform: scale(1.02) translateY(-5px);
  box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.2);
}
.gallery-flexbox .box-hidden {
  margin: 0;
  padding: 0;
  width: 24%;
  opacity: 0;
  visibility: hidden;
}
nav.section-header .header-flexbox {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 60px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  display: flex;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li + li {
  margin-left: 40px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #222;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s;
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 13px;
  }
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #2181bd;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-button {
  cursor: pointer;
  height: 27px;
  padding: 0px 18px 2px 5px;
  border: none;
  background: #e9e9e9;
  border-radius: 3px;
  outline: none;
  position: relative;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-button img {
  height: 21px;
  width: 21px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-button svg {
  position: absolute;
  top: 50%;
  right: 6px;
  fill: #000;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  transform: translateY(-50%) rotate(90deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-menu {
  padding: 0;
  min-width: auto;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-menu li .dropdown-item {
  padding: 0px 16px 2px 5px;
  outline: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages .dropdown-menu li .dropdown-item img {
  height: 21px;
  width: 21px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages + .top-social-media:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .top-languages + .top-navigation:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-of-type(2) {
  display: flex;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-of-type(2) .arrow-down-icon {
  height: 12px;
  margin-left: 5px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) {
  padding-left: 30px;
  margin-left: 30px;
  border-left: 0.5px solid #ccc;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) li:nth-of-type(1) a {
  color: #b8b8b8;
  font-size: 14px;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) li:nth-of-type(1) a:hover {
  color: #2181bd;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) li:nth-of-type(2) a {
  color: #7f7f7f;
  transition: all 0.3s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) li:nth-of-type(2) a:hover {
  color: #2181bd;
}
nav.section-header .header-flexbox .flexbox-sicor img {
  height: 45px;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #222;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
.heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 300;
}
section.section-welcome .welcome-bg {
  height: 70vh;
}
section.section-welcome .container {
  height: 100%;
}
section.section-welcome .welcome-wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
section.section-welcome .welcome-heading {
  font-family: 'Poppins', sans-serif;
  margin-bottom: -50px;
  height: 350px;
  width: 450px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 50px;
}
section.section-welcome .welcome-heading h1 {
  font-weight: 300;
  font-size: 38px;
}
section.section-welcome .welcome-heading p {
  font-size: 14px;
  position: relative;
}
section.section-welcome .welcome-heading p:after {
  position: absolute;
  display: block;
  content: "";
  width: 30px;
  height: 1px;
  margin: 15px 0;
  background: #fff;
}
section.section-welcome .welcome-heading .offer-link {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-heading .offer-link:hover .arrow-right-icon {
  transform: translate3d(5px, 0, 0);
}
section.section-welcome .welcome-heading .offer-link .arrow-right-icon {
  height: 14px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}
section.section-offer {
  padding-top: 120px;
}
section.section-offer .offer-tiles {
  display: flex;
  padding-top: 100px;
}
section.section-offer .offer-tiles .tiles-service {
  width: 50%;
  height: 450px;
}
section.section-offer .offer-tiles .tiles-service .tiles-img {
  height: 100%;
  width: 100%;
}
section.section-offer .offer-tiles .tiles-service .tiles-img .tiles-img-overlay {
  background: rgba(0, 0, 0, 0.6);
  transition: 160ms all ease-in;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 100px;
}
section.section-offer .offer-tiles .tiles-service .tiles-img .tiles-img-overlay:hover {
  background: rgba(0, 0, 0, 0.2);
}
section.section-offer .offer-tiles .tiles-service .tiles-img h2 {
  color: #fff;
  font-size: 38px;
  letter-spacing: 1px;
}
section.section-offer .offer-tiles .tiles-service .tiles-img .offer-link {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}
section.section-offer .offer-tiles .tiles-service .tiles-img .offer-link:hover .arrow-right-icon {
  transform: translate3d(5px, 0, 0);
}
section.section-offer .offer-tiles .tiles-service .tiles-img .offer-link .arrow-right-icon {
  height: 13px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}
section.section-about {
  padding: 100px 0;
}
section.section-about .about-inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
}
section.section-about .about-inner .about-img {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
}
section.section-about .about-inner .about-img .img {
  position: absolute;
  top: 20%;
  left: 10%;
}
section.section-about .about-inner .about-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px;
}
section.section-about .about-inner .about-text h3 {
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 300;
  letter-spacing: 3px;
}
section.section-about .about-inner .about-text p {
  padding: 30px 0;
  font-weight: 300;
}
section.section-services {
  padding: 100px 0;
}
section.section-services .services-wrapper {
  display: flex;
  flex-direction: column;
}
section.section-services .services-wrapper .services-content {
  position: relative;
  z-index: 100;
  display: flex;
  height: 60vh;
}
section.section-services .services-wrapper .services-content::after {
  position: absolute;
  z-index: 1;
  display: block;
  font-size: 230px;
  color: #eeeeee;
  font-weight: 400;
  right: 50%;
  bottom: -25%;
}
section.section-services .services-wrapper .services-content:nth-of-type(1)::after {
  content: "01";
}
section.section-services .services-wrapper .services-content:nth-of-type(2)::after {
  content: "02";
  right: 0;
}
section.section-services .services-wrapper .services-content:nth-of-type(3)::after {
  content: "03";
  bottom: -17%;
}
section.section-services .services-wrapper .services-content .content-text,
section.section-services .services-wrapper .services-content .content-img {
  display: flex;
  align-items: center;
  width: 50%;
}
section.section-services .services-wrapper .services-content .content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 100px;
}
section.section-services .services-wrapper .services-content .content-text:hover h3 {
  color: #2181bd;
}
section.section-services .services-wrapper .services-content .content-text h3 {
  line-height: 38px;
  font-size: 28px;
  transition: all 0.3s;
}
section.section-services .services-wrapper .services-content .content-text a:hover {
  color: #7f7f7f;
}
section.section-gallery .carousel-content {
  display: flex;
  flex-direction: column;
  padding: 100px 0;
}
section.section-gallery .carousel-content .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
section.section-gallery .carousel-content .owl-carousel .owl-controls {
  display: none;
}
section.subpage-section-heading {
  position: relative;
  margin-top: 50px;
}
section.subpage-section-heading .welcome-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -50px;
  left: 0;
}
section.subpage-section-heading .welcome-heading {
  font-family: 'Poppins', sans-serif;
  height: 350px;
  width: 450px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 50px;
  position: relative;
}
section.subpage-section-heading .welcome-heading h1 {
  font-weight: 300;
  line-height: 48px;
  font-size: 38px;
}
section.subpage-section-heading .welcome-heading h1 strong {
  font-weight: 300;
  font-size: 18px;
  font-style: italic;
}
section.subpage-section-heading .welcome-heading p {
  font-size: 14px;
  position: relative;
}
section.subpage-section-heading .welcome-heading p:after {
  position: absolute;
  display: block;
  content: "";
  width: 30px;
  height: 1px;
  background: #fff;
  bottom: -30px;
}
section.subpage-section-heading .welcome-heading .offer-link {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}
section.subpage-section-heading .welcome-heading .offer-link:hover .arrow-right-icon {
  transform: translate3d(5px, 0, 0);
}
section.subpage-section-heading .welcome-heading .offer-link .arrow-right-icon {
  height: 14px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}
section.static-page-section-content {
  padding: 80px 0 100px 0;
  position: relative;
}
section.static-page-section-content .img-absolute-1 {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 33%;
}
section.static-page-section-content .content-inner {
  width: 70%;
  position: relative;
}
section.static-page-section-content .content-inner p {
  font-weight: 300;
  line-height: 28px;
}
section.static-page-section-content .content-inner .gallery-flexbox {
  margin-top: 40px;
}
section.static-page-section-content .content-inner address p {
  padding: 3px 0;
}
section.static-page-section-content .content-inner ul {
  padding-left: 30px;
}
section.static-page-section-content .content-inner ul li {
  font-weight: 300;
  padding-bottom: 10px;
}
section.kontakt-section-content {
  position: relative;
  padding: 50px 0;
}
section.kontakt-section-content h2 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 300;
}
section.kontakt-section-content .img-absolute-1 {
  position: absolute;
  right: 0;
  width: 33%;
  bottom: -100px;
}
section.kontakt-section-content .inner-address p {
  padding: 3px 0;
  font-weight: 300;
  font-size: 18px;
}
section.kontakt-section-content .iframe-map {
  width: 68%;
  margin-top: 50px;
}
section.kontakt-section-content .iframe-map iframe {
  min-height: 350px;
  width: 100%;
  height: 100%;
}
section.section-footer {
  padding: 50px 0;
  background: #232323;
  position: relative;
  overflow: hidden;
}
section.section-footer .footer-flexbox {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 100;
  color: #7f7f7f;
}
section.section-footer .footer-flexbox .flexbox-service .logo {
  height: 80px;
}
section.section-footer .footer-flexbox .flexbox-service h3 {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
  text-transform: uppercase;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 13px;
  color: #7f7f7f;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #2181bd;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #7f7f7f;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 15px;
  transition: all 0.2s;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img:hover {
  transform: scale(1.1);
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #2181bd;
}
section.section-footer .alert {
  position: relative;
  margin: 50px 0 0 0;
  padding: 50px 0 0 0;
  border-radius: 0;
  background: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
section.section-footer .alert p {
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #7f7f7f;
  cursor: pointer;
}
section.section-footer .alert p a:hover {
  color: #2181bd;
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .flexbox-sicor img {
    height: 40px;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) {
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav:nth-of-type(2) li a:nth-of-type(1) {
    font-size: 16px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li + li {
    margin-left: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:nth-of-type(2) {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 18px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.dropdown-desktop {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.dropdown-mobile {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    padding-right: 25px;
  }
  section.section-footer .footer-flexbox .flexbox-service h3 {
    font-size: 14px;
    line-height: 20px;
  }
  section.section-footer .footer-flexbox .flexbox-service .logo {
    height: 60px;
  }
}
@media (max-width: 767.98px) {
  section.static-page-section-content {
    padding: 50px 0;
  }
  section.static-page-section-content .img-absolute-1 {
    display: none;
  }
  section.static-page-section-content .content-inner {
    text-align: center;
    width: 100%;
  }
  section.static-page-section-content .content-inner ul {
    padding-left: 0;
    list-style-type: none;
  }
  section.static-page-section-content .content-inner ul li {
    font-size: 16px;
  }
  section.subpage-section-heading .welcome-wrapper {
    width: 100%;
    align-items: center;
  }
  section.subpage-section-heading .welcome-heading {
    padding: 30px;
    width: 100%;
    margin-bottom: 0;
  }
  section.subpage-section-heading .welcome-heading h1 {
    font-size: 34px;
  }
  section.section-welcome .welcome-wrapper {
    width: 100%;
    align-items: center;
  }
  section.section-welcome .welcome-heading {
    padding: 30px;
    width: 100%;
    margin-bottom: 0;
  }
  section.section-welcome .welcome-heading h1 {
    font-size: 34px;
  }
  section.section-offer {
    padding-top: 100px;
  }
  section.section-offer .offer-tiles {
    padding-top: 50px;
    flex-wrap: wrap;
  }
  section.section-offer .offer-tiles .tiles-service {
    height: 300px;
  }
  section.section-offer .offer-tiles .tiles-service .tiles-img .tiles-img-overlay {
    padding: 0 30px;
  }
  section.section-offer .offer-tiles .tiles-service .tiles-img .tiles-img-overlay h2 {
    font-size: 30px;
  }
  section.section-about {
    padding: 80px 0 0 0;
  }
  section.section-about .about-inner {
    margin-top: 70px;
  }
  section.section-about .about-inner .about-text,
  section.section-about .about-inner .about-img {
    width: 100%;
  }
  section.section-about .about-inner .about-img {
    justify-content: center;
  }
  section.section-about .about-inner .about-img img:nth-of-type(2) {
    display: none;
  }
  section.section-about .about-inner .about-text {
    align-items: center;
    text-align: center;
  }
  section.section-services {
    padding: 50px 0 0 0;
  }
  section.section-services .services-wrapper .services-content {
    height: 40vh;
  }
  section.section-services .services-wrapper .services-content::after {
    font-size: 160px;
  }
  section.section-services .services-wrapper .services-content .content-text {
    padding: 0 30px;
    border: 1px solid rgba(225, 225, 225, 0.4);
  }
  section.section-services .services-wrapper .services-content .content-text h3 {
    font-size: 24px;
  }
  section.section-gallery {
    padding-top: 80px;
  }
  section.section-gallery .carousel-content {
    padding: 50px 0;
  }
  section.kontakt-section-content .content-inner {
    text-align: center;
  }
  section.kontakt-section-content .img-absolute-1 {
    display: none;
  }
  section.kontakt-section-content .iframe-map {
    width: 100%;
  }
  section.section-footer .footer-flexbox {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    padding-right: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  section.section-offer .offer-tiles .tiles-service {
    height: 300px;
    width: 100%;
  }
  section.section-services .services-wrapper .services-content::after {
    right: 0;
    bottom: -10%;
    font-size: 120px;
  }
  section.section-services .services-wrapper .services-content:nth-of-type(3)::after {
    bottom: -10%;
  }
  section.section-services .services-wrapper .services-content .content-text {
    width: 100%;
  }
  section.section-services .services-wrapper .services-content .content-img {
    display: none;
  }
}
