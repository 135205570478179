
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-sicor {
				img {
					height: 40px;
				}
			}

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;
						width: 100%;
						text-align: center;
						&:nth-of-type(2) {
							width: 100%;
							li {
								a:nth-of-type(1) {
									font-size: 16px;
								}
							}
						}
						li {
							padding: 10px 0;

							& + li {
								margin-left: 0;
							}

							&:nth-of-type(2) {
								display: block;
							}
							a {
								font-size: 18px;
							}
							&.dropdown-desktop {
								display: none;
							}
							&.dropdown-mobile {
								display: block;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			.flexbox-service { 
				padding-right: 25px;
				h3 {
					font-size: 14px;
					line-height: 20px;
				}
				.logo {
					height: 60px;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	section.static-page-section-content {
		padding: 50px 0;
		.img-absolute-1 {
			display: none;
		}
		.content-inner {
			text-align: center;
			width: 100%;
			ul {
				padding-left: 0;
				list-style-type: none;
				li {
					font-size: 16px;
				}
			}
		}
	}

	section.subpage-section-heading {
		.welcome-wrapper {
			width: 100%;
			align-items: center;
		}
		.welcome-heading {
			padding: 30px;
			width: 100%;
			margin-bottom: 0;
			h1 {
				font-size: 34px;
			}
		}
	}

	section.section-welcome {
		.welcome-wrapper {
			width: 100%;
			align-items: center;
		}
		.welcome-heading {
			padding: 30px;
			width: 100%;
			margin-bottom: 0;
			h1 {
				font-size: 34px;
			}
		}
	}
	section.section-offer {
		padding-top: 100px;
	 	.offer-tiles {
			 padding-top: 50px;
			 flex-wrap: wrap;
			.tiles-service {
				height: 300px;
				.tiles-img {
					.tiles-img-overlay {
						padding: 0 30px;
						h2 {
							font-size: 30px;
						}
					}
				}
			}
		}
	}
	
	section.section-about {
		padding: 80px 0 0 0;
		.about-inner {
			margin-top: 70px;
			.about-text, .about-img {
				width: 100%;
			}
			.about-img {
				justify-content: center;
				img:nth-of-type(2){
					display: none;
				}
			}
			.about-text {
				align-items: center;
				text-align: center;
			}
		}
	}

	section.section-services {
		padding: 50px 0 0 0;
		.services-wrapper {
			.services-content {
				height: 40vh;
				&::after {
					font-size: 160px;
				}
				.content-text {
					padding: 0 30px;
					border: 1px solid rgba(225, 225, 225, 0.4);
					h3 {
						font-size: 24px;
					}
				}
			}
		}
	}

	section.section-gallery {
		padding-top: 80px;
		.carousel-content {
			padding: 50px 0;
		}
	}

	section.kontakt-section-content {
		.content-inner {
			text-align: center;
		}
		.img-absolute-1 {
			display: none;
		}
		.iframe-map {
			width: 100%;
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			text-align: center;
			.flexbox-service {
				padding-right: 0;
				margin-bottom: 20px;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	section.section-offer {
		.offer-tiles {
		   .tiles-service {
			   height: 300px;
			   width: 100%;
		   }
	   }
   }

   	section.section-services {
		.services-wrapper {
			.services-content {
				&::after {
					right: 0;
					bottom: -10%;
					font-size: 120px;
				}
				&:nth-of-type(3)::after {
					bottom:-10%;
				}
				.content-text {
					width: 100%;
				}
				.content-img {
					display: none;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

}