@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap&subset=latin-ext');


@color1: #2181bd;

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Roboto', sans-serif;
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
		color: #222;
	}
}

.btn-more {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	color: #7f7f7f;
	text-transform: uppercase;
	font-size: 14px;
	margin-top: 50px;
	font-weight: 500;

	&:hover .arrow-right-icon {
		transform: translate3d(5px, 0, 0);
	}

	.arrow-right-icon {
		height: 14px;
		margin-left: 10px;
		transition: transform 0.3s ease;
	}
}

.btn-more-border {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 40px;
	background: #f1f1f1;

	&:hover .arrow-right-icon {
		transform: translate3d(5px, 0, 0);
	}

	a {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 70%;
		color: #7f7f7f;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 500;

		.arrow-right-icon {
			height: 13px;
			transition: transform 0.3s ease;
		}
	}
}

.system-alerts {
	width: 100%;
	text-align: center;

	.alert {
		border-radius: 0;
		border: none;
		height: 60px;
		display: flex;
		align-items: center;
		opacity: 0.9;
		transition: all 0.4s;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 18px;
			}

			p {
				color: #fff;
				padding: 0 50px;
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 18px;
				}
			}
		}

		&.alert-success {
			background: #76ad21;
		}

		&.alert-info {
			background: #218ead;
		}

		&.alert-warning {
			background: #c52b20;
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: @color1;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.gallery-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	a {
		margin-top: calc(4% / 3);
		width: 24%;
		padding-top: 24%;
		position: relative;
		box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0);
		transform-origin: center bottom;
		transition: all 0.4s;

		&:hover {
			transform: scale(1.02) translateY(-5px);
			box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.2);
		}
	}

	.box-hidden {
		margin: 0;
		padding: 0;
		width: 24%;
		opacity: 0;
		visibility: hidden;
	}
}

nav.section-header {
	.header-flexbox {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-logo {
			img {
				height: 60px;
			}
		}

		.flexbox-nav-wrapper {
			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				display: flex;
				justify-content: space-between;

				.flexbox-nav {
					display: flex;
					justify-content: space-between;
					align-items: center;

					li {
						& + li {
							margin-left: 40px;
						}

						a {
							color: #222;
							font-weight: 500;
							font-size: 14px;
							transition: all 0.3s;
							@media (max-width: 991.98px) {
								font-size: 13px;
							}
							&:hover {
								color: @color1;
							}
						}

						// .dropdown {
						// 	.dropdown-menu {
						// 		margin-top: 40px;
						// 		padding: 10px 10px;
						// 		min-width: 7rem;
						// 		background: rgba(9, 59, 115, 0.9);
						// 		border-radius: 0;
						// 		border: none;
						// 		position: relative;

						// 		.dropdown-item {
						// 			padding: 7px;
						// 			font-size: 14px;
						// 			transition: all 0.3s;
						// 			transform: none !important;
						// 			color: #fff;
						// 			cursor: pointer;

						// 			a {
						// 				color: #fff;
						// 			}

						// 			&:hover {
						// 				background: rgba(255, 255, 255, 0.1);
						// 			}
						// 		}

						// 		&:before {
						// 			content: '';
						// 			display: block;
						// 			position: absolute;
						// 			left: 20px;
						// 			top: -5px;
						// 			width: 0;
						// 			height: 0;
						// 			border-style: solid;
						// 			border-width: 0 4px 5px 4px;
						// 			border-color: transparent transparent rgba(9, 59, 115, 0.9) transparent;
						// 		}
						// 	}
						// }
						
						// &.dropdown-mobile {
						// 	display: none;
						// }

						.top-languages {
							.dropdown-button {
								cursor: pointer;
								height: 27px;
								padding: 0px 18px 2px 5px;
								border: none;
								background: #e9e9e9;
								border-radius: 3px;
								outline: none;
								position: relative;

								img {
									height: 21px;
									width: 21px;
								}

								svg {
									position: absolute;
									top: 50%;
									right: 6px;
									fill: #000;
									height: 6px;
									min-width: 6px;
									max-width: 6px;
									transform: translateY(-50%) rotate(90deg);
								}
							}

							.dropdown-menu {
								padding: 0;
								min-width: auto;

								li {
									.dropdown-item {
										padding: 0px 16px 2px 5px;
										outline: none;

										img {
											height: 21px;
											width: 21px;
										}
									}
								}
							}

							& + .top-social-media {
								&:before {
									content: '';
									border-right: 0.5px solid #ccc;
									height: 14px;
									display: inline-block;
									margin: 0 20px 0 24px;
								}
							}

							& + .top-navigation {
								&:before {
									content: '';
									border-right: 0.5px solid #ccc;
									height: 14px;
									display: inline-block;
									margin: 0 20px 0 24px;
								}
							}
						}
					}

					li:nth-of-type(2) {
						display: flex;
						.arrow-down-icon {
							height: 12px;
							margin-left: 5px;
						}
					}

					&:nth-of-type(2) {
						padding-left: 30px;
						margin-left: 30px;
						border-left: 0.5px solid #ccc;

						li:nth-of-type(1) {
							a {
								color: #b8b8b8;
								font-size: 14px;
								transition: all 0.3s;
								&:hover {
									color: @color1;
								}
							}
						}

						li:nth-of-type(2) {
							a {
								color: #7f7f7f;
								transition: all 0.3s;
								&:hover {
									color: @color1;
								}
							}
						}
					}
				}
			}
		}

		.flexbox-sicor {
			img {
				height: 45px;
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;
			
			.button-bar {
				height: 2px;
				width: 24px;
				background: #222;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 4px;
					width: 27px;
				}

				&:nth-child(3) {
					margin-top: 4px;
					width: 30px;
				}
			}

			&:hover, &:focus {
				.button-bar {
					width: 30px !important;
				}
			}
		}
	}
}

.heading {
	h2 {
		text-align: center;
		text-transform: uppercase;
		font-size: 40px;
		font-weight: 300;
	}
}

// SECTION-WELCOME

section.section-welcome {
	.welcome-bg {
		height: 70vh;
	}
	.container {
		height: 100%;
	}
	.welcome-wrapper {
		width: 50%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
	.welcome-heading {
		font-family: 'Poppins', sans-serif;
		margin-bottom: -50px;
		height: 350px;
		width: 450px;
		background: rgba(0, 0, 0, 0.7);
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 30px 50px;
		h1 {
			font-weight: 300;
			font-size: 38px;
		}
		p {
			font-size: 14px;
			position: relative;
			&:after {
				position: absolute;
				display: block;
				content: "";
				width: 30px;
				height: 1px;
				margin: 15px 0;
				background: #fff;
			}
		}
		
		.offer-link {
			color: #fff;
			font-size: 14px;
			display: flex;
			align-items: center;
			&:hover .arrow-right-icon {
				transform: translate3d(5px, 0, 0);
			}
			.arrow-right-icon {
				height: 14px;
				margin-left: 10px;
				transition: transform 0.3s ease;
			}

		}
	}
}

// SECTION-OFFER

section.section-offer {
	padding-top: 120px;
	.offer-tiles {
		display: flex;
		padding-top: 100px;
		.tiles-service {
			width: 50%;
			height: 450px;
			.tiles-img {
				height: 100%;
				width: 100%;
				.tiles-img-overlay {
					background: rgba(0, 0, 0, 0.6);
					transition: 160ms all ease-in;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					padding: 0 100px;
					&:hover {
						background: rgba(0, 0, 0, 0.2);
					}
				}
				h2 {
					color: #fff;
					font-size: 38px;
					letter-spacing: 1px;
				}
				.offer-link {
					color: #fff;
					font-size: 14px;
					display: flex;
					align-items: center;
					&:hover .arrow-right-icon {
						transform: translate3d(5px, 0, 0);
					}
					.arrow-right-icon {
						height: 13px;
						margin-left: 10px;
						transition: transform 0.3s ease;
					}
				}
			}
		}
	}
}

// SECTION-ABOUT

section.section-about {
	padding: 100px 0;
	.about-inner {
		display: flex;
		flex-wrap: wrap;
		margin-top: 100px;
		.about-img {
			position: relative;
			display: flex;
			justify-content: flex-end;
			width: 50%;
			height: 100%;
			.img {
				position: absolute;
				top: 20%;
				left: 10%;
			}
		}
		.about-text {
			display: flex;
			flex-direction: column;
			width: 50%;
			padding: 50px;
			h3 {
				text-transform: uppercase;
				font-size: 46px;
				font-weight: 300;
				letter-spacing: 3px;
			}
			p {
				padding: 30px 0;
				font-weight: 300;
			}
		}
	}
}

// SECTION-SERVICES

section.section-services {
	padding: 100px 0;
	.services-wrapper {
		display: flex;
		flex-direction: column;
		.services-content {
			position: relative;
			z-index: 100;
			display: flex;
			height: 60vh;
			&::after {
				position: absolute;
				z-index: 1;
				display: block;
				font-size: 230px;
				color: #eeeeee;
				font-weight: 400;
				right: 50%;
				bottom: -25%;
			}
			&:nth-of-type(1)::after {
				content: "01";
			}
			&:nth-of-type(2)::after {
				content: "02";
				right: 0;
			}
			&:nth-of-type(3)::after {
				content: "03";
				bottom: -17%;
			}
			.content-text, .content-img {
				display: flex;
				align-items: center;
				width: 50%;
			}
			.content-text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				padding: 0 100px;
				&:hover h3 {
					color: @color1;
				}
				h3 {
					line-height: 38px;
					font-size: 28px;
					transition: all 0.3s;
				}
				a:hover {
					color: #7f7f7f; 
				}
			}
		}
	}
}

section.section-gallery {
	.carousel-content{
		display: flex;
		flex-direction: column;
		padding: 100px 0;
		.owl-carousel {
			.owl-stage {
				display: flex;
				align-items: center;
			}
			.owl-controls {
				display: none;
			}
		}
	}
}

section.subpage-section-heading {
	position: relative;
	margin-top: 50px;

	.welcome-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: -50px;
		left: 0;
	}

	.welcome-heading {
		font-family: 'Poppins', sans-serif;
		height: 350px;
		width: 450px;
		background: rgba(0, 0, 0, 0.7);
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 30px 50px;
		position: relative;

		h1 {
			font-weight: 300;
			line-height: 48px;
			font-size: 38px;

			strong {
				font-weight: 300;
				font-size: 18px;
				font-style: italic;
			}
		}
			p {
			font-size: 14px;
			position: relative;
			&:after {
				position: absolute;
				display: block;
				content: "";
				width: 30px;
				height: 1px;
				background: #fff;
				bottom: -30px;
			}
		}
		
		.offer-link {
			color: #fff;
			font-size: 14px;
			display: flex;
			align-items: center;
			&:hover .arrow-right-icon {
				transform: translate3d(5px, 0, 0);
			}
			.arrow-right-icon {
				height: 14px;
				margin-left: 10px;
				transition: transform 0.3s ease;
			}

		}
	}
}

section.static-page-section-content {
	padding: 80px 0 100px 0;
	position: relative;

	.img-absolute-1 {
		position: absolute;
		right: 0;
		bottom: -100px;
		width: 33%;
	}

	.content-inner {
		width: 70%;
		position: relative;

		p {
			font-weight: 300;
			line-height: 28px;
		}

		.gallery-flexbox {
			margin-top: 40px;
		}
		address {
			p {
				padding: 3px 0;
			}
		}
		ul {
			padding-left: 30px;
			li {
				font-weight: 300;
				padding-bottom: 10px;
			}
		}
	}

}

section.kontakt-section-content {
	position: relative;
	padding: 50px 0;

	h2 {
		margin-bottom: 10px;
		font-size: 40px;
		font-weight: 300;
	}
	.img-absolute-1 {
		position: absolute;
		right: 0;
		width: 33%;
		bottom: -100px;
	}
	.inner-address {
		p {
			padding: 3px 0;
			font-weight: 300;
			font-size: 18px;
		}
	}
	.iframe-map {
		width: 68%;
		margin-top: 50px;
		iframe {
			min-height: 350px;
			width: 100%;
			height: 100%;
		}
	}
}

section.section-footer {
	// margin-top: 1000px;
	padding: 50px 0;
	background: #232323;
	position: relative;
	overflow: hidden;

	.footer-logo {
	}

	.footer-flexbox {
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		position: relative;

		.flexbox-service {
			p {
				margin-top: 10px;
				font-size: 14px;
				font-weight: 100;
				color: #7f7f7f;
			}

			.logo {
				height: 80px;
			}

			h3 {
				margin-top: 0;
				padding-bottom: 10px;
				font-size: 16px;
				text-transform: uppercase;
				color: #e6e6e5;
			}

			ul {
				li {
					margin-top: 10px;
					text-transform: uppercase;

					a {
						font-size: 13px;
						color: #7f7f7f;
						transition: all 0.3s;

						b {
							font-size: 16px;
						}

						span {
							font-size: 10px;
						}

						&:hover {
							color: @color1;
						}
					}

					p {
						font-size: 12px;
						color: #7f7f7f;

						b {
							font-weight: 400;
							font-size: 14px;
						}

						span {
							font-size: 10px;
						}
					}
				}
			}

			.list-social {
				display: flex;
				align-items: center;
				li {
					margin-right: 20px;

					a {
						img {
							height: 15px;
							transition: all 0.2s;
							&:hover {
								transform: scale(1.1);
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.footer-credits {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: 50px;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		p {
			font-size: 12px;
			color: #7f7f7f;
			font-weight: 300;

			a {
				color: @color1;
			}
		}
	}

	.alert {
		position: relative;
		margin: 50px 0 0 0;
		padding: 50px 0 0 0;
		border-radius: 0;
		background: none;
		border: none;
		border-top: 1px solid rgba(255, 255, 255, 0.2);

		p {
			font-size: 12px;
			color: #7f7f7f;
			font-weight: 300;

			a {
				color: #7f7f7f;
				cursor: pointer;
				&:hover {
					color: @color1;
				}
			}
		}
	}
}